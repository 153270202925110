define("discourse/plugins/discourse-docs/discourse/initializers/setup-docs", ["exports", "discourse/lib/plugin-api", "discourse-i18n", "discourse/plugins/discourse-docs/lib/get-docs"], function (_exports, _pluginApi, _discourseI18n, _getDocs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initialize(api, container) {
    const siteSettings = container.lookup("service:site-settings");
    const docsPath = (0, _getDocs.getDocs)();
    api.addKeyboardShortcut("g e", "", {
      path: "/" + docsPath
    });
    if (siteSettings.docs_add_to_top_menu) {
      api.addNavigationBarItem({
        name: "docs",
        displayName: (0, _discourseI18n.i18n)("docs.title"),
        href: "/" + docsPath
      });
    }
    api.registerValueTransformer("topic-list-columns", _ref => {
      let {
        value: columns
      } = _ref;
      if (container.lookup("service:router").currentRouteName === "docs.index") {
        columns.delete("posters");
        columns.delete("replies");
        columns.delete("views");
      }
      return columns;
    });
    api.registerValueTransformer("topic-list-item-expand-pinned", _ref2 => {
      let {
        value
      } = _ref2;
      if (container.lookup("service:router").currentRouteName === "docs.index") {
        return true;
      }
      return value;
    });
  }
  var _default = _exports.default = {
    name: "setup-docs",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      if (!siteSettings.docs_enabled) {
        return;
      }
      (0, _pluginApi.withPluginApi)("0.8", api => initialize(api, container));
      if (siteSettings.docs_add_search_menu_tip) {
        (0, _pluginApi.withPluginApi)("0.12.6", api => {
          api.addSearchSuggestion("in:docs");
          const tip = {
            label: "in:docs",
            description: (0, _discourseI18n.i18n)("docs.search.tip_description"),
            clickable: true,
            searchTopics: true
          };
          api.addQuickSearchRandomTip(tip);
        });
      }
      (0, _pluginApi.withPluginApi)("1.2.0", api => {
        api.addCommunitySectionLink({
          name: "docs",
          route: "docs.index",
          title: (0, _discourseI18n.i18n)("sidebar.docs_link_title"),
          text: (0, _discourseI18n.i18n)("sidebar.docs_link_text")
        });
      });
    }
  };
});