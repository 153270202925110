define("discourse/plugins/discourse-docs/discourse/controllers/docs-index", ["exports", "@ember/application", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/template", "@ember-decorators/object", "discourse/lib/decorators", "discourse/lib/get-url", "discourse-i18n", "discourse/plugins/discourse-docs/discourse/models/docs"], function (_exports, _application3, _controller, _object, _computed, _template, _object2, _decorators, _getUrl, _discourseI18n, _docs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SHOW_FILTER_AT = 10;
  class DocsIndexController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "application", [_controller.inject]))();
    #application = (() => (dt7948.i(this, "application"), void 0))();
    queryParams = [{
      ascending: "ascending",
      filterCategories: "category",
      filterTags: "tags",
      filterSolved: "solved",
      orderColumn: "order",
      searchTerm: "search",
      selectedTopic: "topic"
    }];
    isLoading = false;
    isLoadingMore = false;
    isTopicLoading = false;
    filterTags = null;
    filterCategories = null;
    filterSolved = false;
    searchTerm = null;
    selectedTopic = null;
    topic = null;
    expandedFilters = false;
    ascending = null;
    orderColumn = null;
    static #_2 = (() => dt7948.g(this.prototype, "showCategoryFilter", [(0, _computed.gt)("categories.length", SHOW_FILTER_AT)]))();
    #showCategoryFilter = (() => (dt7948.i(this, "showCategoryFilter"), void 0))();
    categoryFilter = "";
    categorySort = {};
    static #_3 = (() => dt7948.g(this.prototype, "showTagFilter", [(0, _computed.gt)("tags.length", SHOW_FILTER_AT)]))();
    #showTagFilter = (() => (dt7948.i(this, "showTagFilter"), void 0))();
    tagFilter = "";
    tagSort = {};
    static #_4 = (() => dt7948.g(this.prototype, "loadMoreUrl", [(0, _computed.alias)("model.topics.load_more_url")]))();
    #loadMoreUrl = (() => (dt7948.i(this, "loadMoreUrl"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "categories", [(0, _computed.readOnly)("model.categories")]))();
    #categories = (() => (dt7948.i(this, "categories"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "topics", [(0, _computed.alias)("model.topics.topic_list.topics")]))();
    #topics = (() => (dt7948.i(this, "topics"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "tags", [(0, _computed.readOnly)("model.tags")]))();
    #tags = (() => (dt7948.i(this, "tags"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "showExcerpts", [(0, _computed.readOnly)("model.meta.show_topic_excerpts")]))();
    #showExcerpts = (() => (dt7948.i(this, "showExcerpts"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "tagGroups", [(0, _computed.readOnly)("model.tag_groups")]))();
    #tagGroups = (() => (dt7948.i(this, "tagGroups"), void 0))();
    static #_10 = (() => dt7948.g(this.prototype, "topicCount", [(0, _computed.alias)("model.topic_count")]))();
    #topicCount = (() => (dt7948.i(this, "topicCount"), void 0))();
    static #_11 = (() => dt7948.g(this.prototype, "emptyResults", [(0, _computed.equal)("topicCount", 0)]))();
    #emptyResults = (() => (dt7948.i(this, "emptyResults"), void 0))();
    _setupFilters() {
      if (!this.site.mobileView) {
        this.set("expandedFilters", true);
      }
      this.setProperties({
        categorySort: {
          type: "numeric",
          // or alpha
          direction: "desc" // or asc
        },
        tagSort: {
          type: "numeric",
          // or alpha
          direction: "desc" // or asc
        }
      });
    }
    static #_12 = (() => dt7948.n(this.prototype, "_setupFilters", [(0, _object2.on)("init")]))();
    sortedCategories(categories, categorySort, filter) {
      let {
        type,
        direction
      } = categorySort;
      if (type === "numeric") {
        categories = categories.sort((a, b) => a.count - b.count);
      } else {
        categories = categories.sort((a, b) => {
          const first = this.site.categories.findBy("id", a.id).name.toLowerCase(),
            second = this.site.categories.findBy("id", b.id).name.toLowerCase();
          return first.localeCompare(second);
        });
      }
      if (direction === "desc") {
        categories = categories.reverse();
      }
      if (this.showCategoryFilter) {
        return categories.filter(category => {
          let categoryData = this.site.categories.findBy("id", category.id);
          return categoryData.name.toLowerCase().indexOf(filter.toLowerCase()) > -1 || categoryData.description_excerpt && categoryData.description_excerpt.toLowerCase().indexOf(filter.toLowerCase()) > -1;
        });
      }
      return categories;
    }
    static #_13 = (() => dt7948.n(this.prototype, "sortedCategories", [(0, _decorators.default)("categories", "categorySort", "categoryFilter")]))();
    categorySortNumericIcon(catSort) {
      if (catSort.type === "numeric" && catSort.direction === "asc") {
        return "arrow-down-1-9";
      }
      return "arrow-up-1-9";
    }
    static #_14 = (() => dt7948.n(this.prototype, "categorySortNumericIcon", [(0, _decorators.default)("categorySort")]))();
    categorySortAlphaIcon(catSort) {
      if (catSort.type === "alpha" && catSort.direction === "asc") {
        return "arrow-down-a-z";
      }
      return "arrow-up-a-z";
    }
    static #_15 = (() => dt7948.n(this.prototype, "categorySortAlphaIcon", [(0, _decorators.default)("categorySort")]))();
    sortedTags(tags, tagSort, filter) {
      let {
        type,
        direction
      } = tagSort;
      if (type === "numeric") {
        tags = tags.sort((a, b) => a.count - b.count);
      } else {
        tags = tags.sort((a, b) => {
          return a.id.toLowerCase().localeCompare(b.id.toLowerCase());
        });
      }
      if (direction === "desc") {
        tags = tags.reverse();
      }
      if (this.showTagFilter) {
        return tags.filter(tag => {
          return tag.id.toLowerCase().indexOf(filter.toLowerCase()) > -1;
        });
      }
      return tags;
    }
    static #_16 = (() => dt7948.n(this.prototype, "sortedTags", [(0, _decorators.default)("tags", "tagSort", "tagFilter")]))();
    sortedTagGroups(tagGroups, tagSort, filter) {
      let {
        type,
        direction
      } = tagSort;
      let sortedTagGroups = [...tagGroups];
      if (type === "numeric") {
        sortedTagGroups.forEach(group => {
          group.totalCount = group.tags.reduce((acc, curr) => acc + curr.count, 0);
        });
        sortedTagGroups.sort((a, b) => b.totalCount - a.totalCount);
      } else {
        sortedTagGroups.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      }
      if (direction === "desc") {
        sortedTagGroups.reverse();
      }
      if (this.showTagFilter) {
        return sortedTagGroups.filter(tag => tag.id.toLowerCase().includes(filter.toLowerCase()));
      }
      return sortedTagGroups;
    }
    static #_17 = (() => dt7948.n(this.prototype, "sortedTagGroups", [(0, _decorators.default)("tagGroups", "tagSort", "tagFilter")]))();
    tagSortNumericIcon(tagSort) {
      if (tagSort.type === "numeric" && tagSort.direction === "asc") {
        return "arrow-down-1-9";
      }
      return "arrow-up-1-9";
    }
    static #_18 = (() => dt7948.n(this.prototype, "tagSortNumericIcon", [(0, _decorators.default)("tagSort")]))();
    tagSortAlphaIcon(tagSort) {
      if (tagSort.type === "alpha" && tagSort.direction === "asc") {
        return "arrow-down-a-z";
      }
      return "arrow-up-a-z";
    }
    static #_19 = (() => dt7948.n(this.prototype, "tagSortAlphaIcon", [(0, _decorators.default)("tagSort")]))();
    noContent(topics, isSearching, filterSolved) {
      const filtered = isSearching || filterSolved;
      return this.topicCount === 0 && !filtered;
    }
    static #_20 = (() => dt7948.n(this.prototype, "noContent", [(0, _decorators.default)("topics", "isSearching", "filterSolved")]))();
    canLoadMore(loadMoreUrl) {
      return loadMoreUrl === null ? false : true;
    }
    static #_21 = (() => dt7948.n(this.prototype, "canLoadMore", [(0, _decorators.default)("loadMoreUrl")]))();
    isSearching(searchTerm) {
      return !!searchTerm;
    }
    static #_22 = (() => dt7948.n(this.prototype, "isSearching", [(0, _decorators.default)("searchTerm")]))();
    isSearchingOrFiltered(isSearching, filterSolved) {
      return isSearching || filterSolved;
    }
    static #_23 = (() => dt7948.n(this.prototype, "isSearchingOrFiltered", [(0, _decorators.default)("isSearching", "filterSolved")]))();
    canFilterSolved() {
      return this.siteSettings.solved_enabled && this.siteSettings.docs_add_solved_filter;
    }
    static #_24 = (() => dt7948.n(this.prototype, "canFilterSolved", [_decorators.default]))();
    filtered(filterTags) {
      return !!filterTags;
    }
    static #_25 = (() => dt7948.n(this.prototype, "filtered", [(0, _decorators.default)("filterTags")]))();
    shouldShowTags(tagging_enabled, shouldShowTagsByGroup) {
      return tagging_enabled && !shouldShowTagsByGroup;
    }
    static #_26 = (() => dt7948.n(this.prototype, "shouldShowTags", [(0, _decorators.default)("siteSettings.tagging_enabled", "shouldShowTagsByGroup")]))();
    shouldShowTagsByGroup(show_tags_by_group, docs_tag_groups) {
      return show_tags_by_group && Boolean(docs_tag_groups);
    }
    static #_27 = (() => dt7948.n(this.prototype, "shouldShowTagsByGroup", [(0, _decorators.default)("siteSettings.show_tags_by_group", "siteSettings.docs_tag_groups")]))();
    emptyState() {
      let body = (0, _discourseI18n.i18n)("docs.no_docs.body");
      if (this.docsCategoriesAndTags.length) {
        body += (0, _discourseI18n.i18n)("docs.no_docs.to_include_topic_in_docs");
        body += ` (${this.docsCategoriesAndTags.join(", ")}).`;
      } else if (this.currentUser.staff) {
        const setUpPluginMessage = (0, _discourseI18n.i18n)("docs.no_docs.setup_the_plugin", {
          settingsUrl: (0, _getUrl.default)("/admin/site_settings/category/plugins?filter=plugin:discourse-docs")
        });
        body += ` ${setUpPluginMessage}`;
      }
      return {
        title: (0, _discourseI18n.i18n)("docs.no_docs.title"),
        body: (0, _template.htmlSafe)(body)
      };
    }
    static #_28 = (() => dt7948.n(this.prototype, "emptyState", [(0, _decorators.default)()]))();
    docsCategoriesAndTags(docsCategories, docsTags) {
      return docsCategories.concat(docsTags);
    }
    static #_29 = (() => dt7948.n(this.prototype, "docsCategoriesAndTags", [(0, _decorators.default)("docsCategories", "docsTags")]))();
    docsCategories() {
      if (!this.siteSettings.docs_categories) {
        return [];
      }
      return this.siteSettings.docs_categories.split("|").map(c => this.site.categories.findBy("id", parseInt(c, 10))?.name).filter(Boolean);
    }
    static #_30 = (() => dt7948.n(this.prototype, "docsCategories", [(0, _decorators.default)()]))();
    docsTags() {
      if (!this.siteSettings.docs_tags) {
        return [];
      }
      return this.siteSettings.docs_tags.split("|").map(t => `#${t}`);
    }
    static #_31 = (() => dt7948.n(this.prototype, "docsTags", [(0, _decorators.default)()]))();
    toggleCategorySort(newType) {
      let {
        type,
        direction
      } = this.categorySort;
      this.set("categorySort", {
        type: newType,
        direction: type === newType ? direction === "asc" ? "desc" : "asc" : "asc"
      });
    }
    static #_32 = (() => dt7948.n(this.prototype, "toggleCategorySort", [_object.action]))();
    toggleTagSort(newType) {
      let {
        type,
        direction
      } = this.tagSort;
      this.set("tagSort", {
        type: newType,
        direction: type === newType ? direction === "asc" ? "desc" : "asc" : "asc"
      });
    }
    static #_33 = (() => dt7948.n(this.prototype, "toggleTagSort", [_object.action]))();
    onChangeFilterSolved(solvedFilter) {
      this.set("filterSolved", solvedFilter);
    }
    static #_34 = (() => dt7948.n(this.prototype, "onChangeFilterSolved", [_object.action]))();
    updateSelectedTags(tag) {
      let filter = this.filterTags;
      if (filter && filter.includes(tag.id)) {
        filter = filter.split("|").filter(f => f !== tag.id).join("|");
      } else if (filter) {
        filter = `${filter}|${tag.id}`;
      } else {
        filter = tag.id;
      }
      this.setProperties({
        filterTags: filter,
        selectedTopic: null
      });
    }
    static #_35 = (() => dt7948.n(this.prototype, "updateSelectedTags", [_object.action]))();
    updateSelectedCategories(category) {
      const filterCategories = category.id === parseInt(this.filterCategories, 10) ? null : category.id;
      this.setProperties({
        filterCategories,
        selectedTopic: null
      });
      return false;
    }
    static #_36 = (() => dt7948.n(this.prototype, "updateSelectedCategories", [_object.action]))();
    performSearch(term) {
      if (term === "") {
        this.set("searchTerm", null);
        return false;
      }
      if (term.length < this.siteSettings.min_search_term_length) {
        return false;
      }
      this.setProperties({
        searchTerm: term,
        selectedTopic: null
      });
    }
    static #_37 = (() => dt7948.n(this.prototype, "performSearch", [_object.action]))();
    sortBy(column) {
      const order = this.orderColumn;
      const ascending = this.ascending;
      if (column === "title") {
        this.set("orderColumn", "title");
      } else if (column === "activity") {
        this.set("orderColumn", "activity");
      }
      if (!ascending && order) {
        this.set("ascending", true);
      } else {
        this.set("ascending", "");
      }
    }
    static #_38 = (() => dt7948.n(this.prototype, "sortBy", [_object.action]))();
    loadMore() {
      if (this.canLoadMore && !this.isLoadingMore) {
        this.set("isLoadingMore", true);
        _docs.default.loadMore(this.loadMoreUrl).then(result => {
          const topics = this.topics.concat(result.topics.topic_list.topics);
          this.setProperties({
            topics,
            loadMoreUrl: result.topics.load_more_url || null,
            isLoadingMore: false
          });
        });
      }
    }
    static #_39 = (() => dt7948.n(this.prototype, "loadMore", [_object.action]))();
    toggleFilters() {
      if (!this.expandedFilters) {
        this.set("expandedFilters", true);
      } else {
        this.set("expandedFilters", false);
      }
    }
    static #_40 = (() => dt7948.n(this.prototype, "toggleFilters", [_object.action]))();
    returnToList() {
      this.set("selectedTopic", null);
      (0, _application3.getOwner)(this).lookup("service:router").transitionTo("docs");
    }
    static #_41 = (() => dt7948.n(this.prototype, "returnToList", [_object.action]))();
  }
  _exports.default = DocsIndexController;
});