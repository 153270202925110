define("discourse/plugins/discourse-docs/discourse/components/docs-category", ["exports", "@ember/component", "@ember-decorators/component", "discourse/lib/decorators", "@ember/template-factory"], function (_exports, _component, _component2, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <a
    href
    {{action this.selectCategory}}
    class="docs-item docs-category {{if this.category.active 'selected'}}"
  >
    {{d-icon (if this.category.active "circle-xmark" "far-circle")}}
  
    <span class="docs-item-id category-id">{{this.categoryName}}</span>
    <span class="docs-item-count category-count">{{this.category.count}}</span>
  </a>
  */
  {
    "id": "GSCpE9Td",
    "block": "[[[11,3],[24,6,\"\"],[16,0,[29,[\"docs-item docs-category \",[52,[30,0,[\"category\",\"active\"]],\"selected\"]]]],[4,[38,2],[[30,0],[30,0,[\"selectCategory\"]]],null],[12],[1,\"\\n  \"],[1,[28,[35,3],[[52,[30,0,[\"category\",\"active\"]],\"circle-xmark\",\"far-circle\"]],null]],[1,\"\\n\\n  \"],[10,1],[14,0,\"docs-item-id category-id\"],[12],[1,[30,0,[\"categoryName\"]]],[13],[1,\"\\n  \"],[10,1],[14,0,\"docs-item-count category-count\"],[12],[1,[30,0,[\"category\",\"count\"]]],[13],[1,\"\\n\"],[13]],[],false,[\"a\",\"if\",\"action\",\"d-icon\",\"span\"]]",
    "moduleName": "discourse/plugins/discourse-docs/discourse/components/docs-category.hbs",
    "isStrictMode": false
  });
  const DocsCategory = dt7948.c(class DocsCategory extends _component.default {
    categoryName(category) {
      return this.site.categories.findBy("id", category.id).name;
    }
    static #_ = (() => dt7948.n(this.prototype, "categoryName", [(0, _decorators.default)("category")]))();
  }, [(0, _component2.tagName)("")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DocsCategory);
});